













































































import { Component, Ref, Prop } from 'vue-property-decorator';
import TransformComponent from '@common-src/mixins/transform-component';
import UserService from '@common-src/service/user-v2';
import { UserQueryModel } from '@common-src/entity-model/user-entity';
import OrgService from '@common-src/service/org';
import OrgDepartmentService from '@common-src/service/org-department';
import { flattenTree } from '@common-src/utils/tree-util';
import RoleService from '@common-src/service/role-v2';
import { message } from 'ant-design-vue';

@Component
export default class LinkUserDepartmentDialog extends TransformComponent {
    @Prop({ default: 'role' })
    type: string;

    roleId: string = null;
    orgTreeData: Array<any> = null;
    userService: UserService = new UserService();
    orgIdsSearch: Array<string> = null;
    userNameSearch: string = null;
    searchType: string = 'user';

    treeData: any = undefined;
    flattenTreeData : any = [];
    checkedKeys: any = [];

    get RightDataList() {
        return _.uniqBy(_.filter(this.flattenTreeData, r => _.includes(this.checkedKeys, r.id)), 'id');
    }

    get AllCheck() {
        if (_.isEmpty(this.orgTreeData)) {
            return false;
        }
        return _.every(this.flattenTreeData, r => _.includes(this.checkedKeys, r.id));
    }

    get RightDataListInfo() {
        if (_.isEmpty(this.RightDataList)) {
            return '';
        }
        const info = _.countBy(this.RightDataList, 'type');
        return `${info['default'] || 0}个组织，${info['user'] || 0}个成员`;
    }

    allCheckChange(e: any) {
        if (e.target.checked) {
            this.checkedKeys = _.uniq(_.map(this.flattenTreeData, r => r.id));
        } else {
            this.checkedKeys = [];
        }
    }

    initOrgTree() {
        const body = {
            searchUser: this.searchType === 'user' ? this.userNameSearch : null,
            orgName: this.searchType === 'org' ? this.userNameSearch : null,
            excludeOrgUser: this.type === 'user' ? this.roleId : null
        };

        OrgDepartmentService.getOrgUserTreeHasUserV2(body, this.type === 'user' ? { noProject: true } : null).then(res => {
            this.orgTreeData = res;
            this.flattenTreeData = flattenTree(this.orgTreeData, 'children');
        });
    }

    created() {
        this.initOrgTree();
    }

    dialogOpen(roleId: string, selectedKeys: string[] = [], type: string = 'user') {
        this.roleId = roleId;
        this.searchType = type;
        this.dialogVisible = true;
        this.initOrgTree();
        this.checkedKeys = selectedKeys;
    }

    removeAllClick() {
        this.checkedKeys = [];
    }

    removeItemClick(id) {
        const index = _.findIndex(this.checkedKeys, item => item === id);
        if (index > -1) {
            this.checkedKeys.splice(index, 1);
        }
    }

    onCheck(checkedKeys:any) {
        this.checkedKeys = checkedKeys.checked;
    }

    async dialogOK() {
        if (_.isEmpty(this.RightDataList)) {
            message.warning('请选择用户或组织');
            return;
        }
        if (this.type === 'role') {
            const ids = _.groupBy(this.RightDataList, 'type');
            const userIds = _.map(ids['user'], r => r.id);
            const departmentIds = _.map(ids['default'], r => r.id);

            Promise.all([
                userIds.length > 0 ? this.userService.saveLinkUser(this.roleId, userIds) : new Promise((resolve, reject) => {
                    resolve();
                }),
                departmentIds.length > 0 ? this.userService.saveLinkDepartment(this.roleId, departmentIds) : new Promise((resolve, reject) => {
                    resolve();
                })
            ]).then(() => {
                message.success('添加成功');
                this.dialogClose();
                this.$emit('dialogOK');
            });
        }

        if (this.type === 'user') {
            const ids = _.groupBy(this.RightDataList, 'type');
            const userIds = _.map(ids['user'], r => r.id);
            const orgUserIds = [];

            _.forEach(ids['default'], r => {
                orgUserIds.push(..._.map(_.filter(r.children, item => item.type === 'user'), subitem => subitem.id));
            });

            OrgService.addOrgUsers(this.roleId, _.uniq([...userIds, ...orgUserIds])).then(() => {
                message.success('添加成功');
                this.dialogClose();
                this.$emit('dialogOK');
            });
        }
    }

    dialogClose(): void {
        this.userNameSearch = null;
        this.orgIdsSearch = [];
        this.dialogVisible = false;
    }
}
