var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          staticClass: "form-edit-component-max",
          attrs: { title: "添加", visible: _vm.dialogVisible },
          on: { cancel: _vm.dialogClose },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "link-user-component form-edit-component-max transform-dialog",
            },
            [
              _c(
                "a-spin",
                { attrs: { spinning: _vm.listLoading } },
                [
                  _c(
                    "a-row",
                    {
                      staticClass: "transform-header",
                      attrs: { type: "flex", align: "bottom" },
                    },
                    [
                      _c("a-col", { attrs: { span: 11 } }, [_vm._v(" 选择 ")]),
                      _c("a-col", { attrs: { span: 11, offset: 2 } }, [
                        _c(
                          "span",
                          { staticStyle: { "font-weight": "bolder" } },
                          [_vm._v("已选: " + _vm._s(_vm.RightDataListInfo))]
                        ),
                        _c(
                          "a",
                          {
                            staticClass:
                              "transform-remove-all jtl-del-link pull-right",
                            on: { click: _vm.removeAllClick },
                          },
                          [_vm._v("清空")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        {
                          staticClass: "transform-column left-transform-column",
                          attrs: { span: 11 },
                        },
                        [
                          _c(
                            "a-row",
                            {
                              staticStyle: { padding: "8px 16px" },
                              attrs: { type: "flex", align: "middle" },
                            },
                            [
                              _c(
                                "a-input-group",
                                { staticClass: "flex", attrs: { compact: "" } },
                                [
                                  _c("a-input-search", {
                                    staticStyle: {
                                      width: "calc(100% - 150px)",
                                    },
                                    attrs: {
                                      placeholder: "请输入相应名称",
                                      "allow-clear": "",
                                    },
                                    on: { search: _vm.initOrgTree },
                                    model: {
                                      value: _vm.userNameSearch,
                                      callback: function ($$v) {
                                        _vm.userNameSearch = $$v
                                      },
                                      expression: "userNameSearch",
                                    },
                                  }),
                                  _c(
                                    "a-select",
                                    {
                                      staticStyle: { width: "100px" },
                                      model: {
                                        value: _vm.searchType,
                                        callback: function ($$v) {
                                          _vm.searchType = $$v
                                        },
                                        expression: "searchType",
                                      },
                                    },
                                    [
                                      _c(
                                        "a-select-option",
                                        { attrs: { value: "user" } },
                                        [_vm._v("搜成员")]
                                      ),
                                      _c(
                                        "a-select-option",
                                        { attrs: { value: "org" } },
                                        [_vm._v("搜部门")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-checkbox",
                            {
                              staticClass: "m-l-16",
                              attrs: { checked: _vm.AllCheck },
                              on: { change: _vm.allCheckChange },
                            },
                            [_vm._v("全选")]
                          ),
                          _c("a-tree", {
                            staticClass: "m-t-16 user-org-tree-custom",
                            attrs: {
                              checkable: "",
                              "auto-expand-parent": true,
                              "default-expand-all": true,
                              "checked-keys": _vm.checkedKeys,
                              "check-strictly": true,
                              "tree-data": _vm.orgTreeData,
                              "replace-fields": { key: "id" },
                            },
                            on: { check: _vm.onCheck },
                            scopedSlots: _vm._u([
                              {
                                key: "title",
                                fn: function (item) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "flex justify-between" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "flex" },
                                          [
                                            item.type === "user"
                                              ? _c("a-icon", {
                                                  staticClass: "tree-icon",
                                                  attrs: { type: "user" },
                                                })
                                              : item.expanded
                                              ? _c("img", {
                                                  staticClass: "tree-icon",
                                                  attrs: {
                                                    src: "/img/tree-org-expand.png",
                                                  },
                                                })
                                              : _c("img", {
                                                  staticClass: "tree-icon",
                                                  attrs: {
                                                    src: "/img/tree-org.png",
                                                  },
                                                }),
                                            _c(
                                              "div",
                                              { staticClass: "m-l-8" },
                                              [_vm._v(" " + _vm._s(item.title))]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        {
                          staticClass: "transform-column-middle",
                          attrs: { span: 2 },
                        },
                        [
                          _c("jtl-icon", {
                            staticClass: "center",
                            attrs: { type: "transform" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        {
                          staticClass: "transform-column p-12",
                          attrs: { span: 11 },
                        },
                        _vm._l(_vm.RightDataList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: item.id + "&" + index,
                              staticClass: "flex m-b-4",
                              staticStyle: {
                                width: "100%",
                                "align-items": "flex-start",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-ellipsis m-r-16",
                                  staticStyle: { "min-width": "200px" },
                                  attrs: { title: item.title },
                                },
                                [_vm._v(" " + _vm._s(item.title) + " ")]
                              ),
                              _c(
                                "div",
                                { staticClass: "link-user-org" },
                                _vm._l(item.orgName, function (subItem, i) {
                                  return _c(
                                    "div",
                                    {
                                      key: i,
                                      staticClass: "text-ellipsis m-b-4",
                                      staticStyle: { width: "100%" },
                                      attrs: { title: subItem },
                                    },
                                    [_vm._v(_vm._s(subItem))]
                                  )
                                }),
                                0
                              ),
                              _c("a-icon", {
                                staticStyle: {
                                  color: "#e07460",
                                  height: "20px",
                                },
                                attrs: { type: "close-circle" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeItemClick(item.id)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "jtl-button",
                { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
                [_vm._v("取消")]
              ),
              _c(
                "jtl-button",
                {
                  attrs: {
                    type: "primary",
                    "click-prop": _vm.dialogOK.bind(this),
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }